import * as React from "react";
import { ReactNode } from "react";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../../hooks";
import * as styles from "./Layout.module.scss";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { Header } from "./Header/Header";

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  socialImage?: string
};


const Layout = ({
                  children,
                  title,
                  description,
                  socialImage
                }: Props) => {
  const {
    author,
    url
  } = useSiteMetadata();

  deckDeckGoHighlightElement();

  const metaImage = typeof socialImage !== "undefined" ? socialImage : author.photo;
  const metaImageUrl = url + metaImage;

  return (
    <div className={styles.layout}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content={title} />
        <meta name="og:title" content={title} />
        <meta property="og:image" content={metaImageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaImageUrl} />
      </Helmet>
      <Header />
      {children}
    </div>
  );
};

export default Layout;
