import * as React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { baseColor } from "../../../constants/emotion";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const header = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
`;

const links = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export function Header() {
	const isMobile = useCheckMobileScreen();
	let linkFontSize = "x-large";
	if (isMobile) {
		linkFontSize = "small";
	}

	const link = css`
		padding: 1rem;
		color: ${baseColor};
		font-weight: bold;
		font-size: ${linkFontSize};
	`;

	return (
		<div
			css={css`
				margin-bottom: 3rem;
			`}>
			<header css={header}>
				<div>
					<Link css={link} to="/">
						Fatih Doğmuş
					</Link>
				</div>
				<div css={links}>
					<div className="hidden sm:block">
						<Link to="/pages/hakkimda" css={link}>
							Hakkımda
						</Link>
						<Link to="/pages/konusmalar" css={link}>
							Konuşmalar
						</Link>
					</div>
				</div>
			</header>
			<hr />
		</div>
	);
}