import * as React from "react";
import { css } from "@emotion/react";

type Props = {
  name: string,
  icon: {
    viewBox?: string,
    path?: string
  }
};

const iconStyle = css`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-style: normal;
  font-weight: normal;
  speak: none;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Icon = ({
                name,
                icon
              }: Props) => (
  <svg css={iconStyle} viewBox={icon.viewBox}>
    <title>{name}</title>
    <path d={icon.path} />
  </svg>
);

export default Icon;
